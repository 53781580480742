import { default as aboutVMHecu1BOSMeta } from "/builds/helmundwalter/homepage/_common/pages/about.vue?macro=true";
import { default as _91name_93hVufqUykBvMeta } from "/builds/helmundwalter/homepage/_common/pages/employees/[name].vue?macro=true";
import { default as indexiaacryv0w3Meta } from "/builds/helmundwalter/homepage/_common/pages/employees/index.vue?macro=true";
import { default as imprint747Ie0hiDFMeta } from "/builds/helmundwalter/homepage/_common/pages/imprint.vue?macro=true";
import { default as index5KQdS2XsOnMeta } from "/builds/helmundwalter/homepage/saxony.ai/pages/index.vue?macro=true";
import { default as privacyWagMjnOrWzMeta } from "/builds/helmundwalter/homepage/_common/pages/privacy.vue?macro=true";
import { default as sitemapA6Nd1ydypyMeta } from "/builds/helmundwalter/homepage/_common/pages/sitemap.vue?macro=true";
import { default as component_45stubw4F8sQ3PNxMeta } from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubw4F8sQ3PNx } from "/builds/helmundwalter/homepage/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___de",
    path: "/ueber-uns",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/about.vue")
  },
  {
    name: "employees-name___de",
    path: "/mitarbeiter/:name()",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/employees/[name].vue")
  },
  {
    name: "employees-name___en",
    path: "/en/employees/:name()",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/employees/[name].vue")
  },
  {
    name: "employees___de",
    path: "/mitarbeiter",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/employees/index.vue")
  },
  {
    name: "employees___en",
    path: "/en/employees",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/employees/index.vue")
  },
  {
    name: "imprint___de",
    path: "/impressum",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/imprint.vue")
  },
  {
    name: "imprint___en",
    path: "/en/imprint",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/imprint.vue")
  },
  {
    name: "index___de",
    path: "/",
    meta: index5KQdS2XsOnMeta || {},
    component: () => import("/builds/helmundwalter/homepage/saxony.ai/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: index5KQdS2XsOnMeta || {},
    component: () => import("/builds/helmundwalter/homepage/saxony.ai/pages/index.vue")
  },
  {
    name: "privacy___de",
    path: "/datenschutz",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/privacy.vue")
  },
  {
    name: "sitemap___de",
    path: "/sitemap",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/builds/helmundwalter/homepage/_common/pages/sitemap.vue")
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/s1",
    component: component_45stubw4F8sQ3PNx
  },
  {
    name: component_45stubw4F8sQ3PNxMeta?.name,
    path: "/tb01",
    component: component_45stubw4F8sQ3PNx
  }
]