<script lang="ts" setup>
import type { NuxtError } from "#app";

const props = defineProps({
  error: Object as () => NuxtError,
});

const localeRoute = useLocaleRoute();

const { t } = useI18n();
</script>

<template>
  <v-responsive v-if="error?.statusCode === 404" height="100vh">
    <nuxt-picture
      sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
      src="/images/hero/about.jpg"
      class="w-100"
      alt=""
      :img-attrs="{
        style: { height: '100%', width: '100%', objectFit: 'cover' },
      }"
    />

    <div
      class="d-flex flex-column justify-center align-center h-100 w-100 position-absolute text-background"
      style="top: 0"
    >
      <h1 class="error-shadow">{{ t("error.404") }}</h1>

      <v-btn :to="localeRoute({ name: 'index' })" variant="text">
        <span class="error-shadow">{{ t("error.home") }}</span>
      </v-btn>
    </div>
  </v-responsive>

  <div v-else class="d-flex flex-column justify-center align-center h-100">
    <h1 class="error-shadow">{{ error?.message }}</h1>

    <v-btn :to="localeRoute({ name: 'index' })" variant="text">
      <span class="error-shadow">{{ t("error.home") }}</span>
    </v-btn>
  </div>
</template>

<style>
.error-shadow {
  text-shadow: 2px 2px #0e1626;
}
</style>
