
import * as ipxStaticRuntime$lLfo5LWgMK from '/builds/helmundwalter/homepage/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 600,
    "md": 959,
    "lg": 1279,
    "xl": 1919,
    "xxl": 2559,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['ipxStatic']: { provider: ipxStaticRuntime$lLfo5LWgMK, defaults: {} }
}
        