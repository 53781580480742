import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: sentry.release,
    integrations: [
      Sentry.browserTracingIntegration({ router, enableInp: true }),
      // Sentry.replayIntegration({
      //   ignore: [".v-field__input"],
      // }),
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.2,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "https://helmundwalter.de",
      "https://saxony.ai",
    ],

    replaysSessionSampleRate: process.env.NODE_ENV == "development" ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
});
