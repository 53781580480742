export type TeamVerticalDefaultProps = {
    slug?: string;
    title?: string;
    iconPath?: string;
    subtitle?: string;
    cardColor?: string;
    textColor?: string;
}

export function getTeamColor(values: TeamVerticalDefaultProps){
    return {
          backgroundColor: values.cardColor
            ? "#" + values.cardColor
            : `var(--card-color-${values.slug})`,
    }
}

export function getTeamIconPath(values: TeamVerticalDefaultProps){
  if (values.iconPath) {
    return values.iconPath;
  }
  if (values.slug) {
    return `/images/${values.slug}-logo.svg`; // Default icon path using slug
  }
  return null;
}
