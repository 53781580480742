type Config = {
  replyTo: string;
  subject: string;
  text: string;
  html?: string;
};

export default defineNuxtPlugin(() => ({
  provide: {
    mail: {
      send: async (config: Config) => {
        try {
          await $fetch("/mail/send", { body: config, method: "POST" });
        } catch (error: any) {
          throw new Error(error.response._data.statusMessage);
        }
      },
    },
  },
}));
